import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {AuthService} from '../services/auth.service';
import {Router} from '@angular/router';
import * as AuthActions from '../actions/auth.action';
import {catchError, map, mergeMap, tap} from 'rxjs/operators';
import {of} from 'rxjs';

@Injectable()
export class AuthEffects {
  login$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.loginStart),
      mergeMap(action =>
        this.authService.login(action.username, action.password).pipe(
          map(response => AuthActions.loginSuccess({
            ssid: response.ssid,
            userKey: response.userKey,
            token: response.token,
            user: response.user,
            status: response.status
          })),
          catchError(error => of(AuthActions.loginFailure({error: error.message})))
        )
      )
    )
  );

  changePassword$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.changePasswordStart),
      mergeMap(action =>
        this.authService.changePassword(action.username, action.password, action.newPassword).pipe(
          map(response => AuthActions.changePasswordSuccess({
            status: response.status
          })),
          catchError(error => of(AuthActions.changePasswordFailure({error: error.message})))
        )
      )
    )
  );
  changePasswordSuccess$ = createEffect(() =>
      this.actions$.pipe(
        ofType(AuthActions.changePasswordSuccess),
        tap(action => {

          if (action.status == true) {
            window.location.replace("/")
          }
        })
      ),
    {dispatch: false}
  );

  loginSuccess$ = createEffect(() =>
      this.actions$.pipe(
        ofType(AuthActions.loginSuccess),
        tap(action => {

          localStorage.setItem('ssid', action.ssid);
          localStorage.setItem('userKey', action.userKey);
          localStorage.setItem('token', action.token);

          if (action.status == "Valid") {
            window.location.replace("/")
          } else if (action.status == "PasswordExpired") {
            this.router.navigate(['/auth/resetPassword']);
          }
        })
      ),
    {dispatch: false}
  );

  register$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.registerStart),
      mergeMap(action =>
        this.authService.register(action.username, action.password).pipe(
          map(response => AuthActions.registerSuccess({
            ssid: response.ssid,
            userKey: response.userKey
          })),
          catchError(error => of(AuthActions.loginFailure({error: error.message})))
        )
      )
    )
  );

  registerSuccess$ = createEffect(() =>
      this.actions$.pipe(
        ofType(AuthActions.registerSuccess),
        tap(action => {
          localStorage.setItem('ssid', action.ssid);
          localStorage.setItem('userKey', action.userKey);
          this.router.navigate(['/']);
        })
      ),
    {dispatch: false}
  );

  autoAuthentication$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.autoAuthenticationStart),
      mergeMap(action =>
        this.authService.authentication().pipe(
          map(response => AuthActions.autoAuthenticationSuccess({
            user: response
          })),
          catchError(error => of(AuthActions.autoAuthenticationFailure({error: error.message})))
        )
      )
    )
  );


  logout$ = createEffect(() =>
      this.actions$.pipe(
        ofType(AuthActions.logout),
        tap(() => {
          this.router.navigate(['/auth/login']);
        })
      ),
    {dispatch: false}
  );

  loadAuthFromLocalStorage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.loadAuthFromLocalStorage),
      map(() => {
        const token = localStorage.getItem('token');
        const userKey = localStorage.getItem('userKey');
        const ssid = localStorage.getItem('ssid');
        if (token && userKey && ssid) {
          return AuthActions.autoAuthenticationStart({token, userKey, ssid});
        } else {
          return AuthActions.logout();
        }
      })
    )
  );

  updateMyInfo$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.updateMyInfoStart),
      mergeMap(action =>
        this.authService.updateMyInfo(action.value).pipe(
          map(response => AuthActions.updateMyInfoSuccess({
            user: response
          })),
          catchError(error => of(AuthActions.loginFailure({error: error.message})))
        )
      )
    )
  );

  uploadImage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.uploadImageStart),
      mergeMap(action =>
        this.authService.uploadImage(action).pipe(
          map(response => AuthActions.uploadImageSuccess({
            user: response
          })),
          catchError(error => of(AuthActions.uploadImageFailure({error: error.message})))
        )
      )
    )
  );

  constructor(
    private actions$: Actions,
    private authService: AuthService,
    private router: Router
  ) {
  }
}
