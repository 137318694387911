import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {StoreModule} from "@ngrx/store";
import {EffectsModule} from "@ngrx/effects";


import {AuthEffects} from './store/effects/auth.effects'

import {authReducer} from "./store/reducers/auth.reducer";
import {LoginComponent} from "./pages/login/login.component";
import {RegisterComponent} from "./pages/register/register.component";
import {UserDetailsComponent} from "./pages/user-details/user-details.component";
import {SubscriptionComponent} from "./pages/Subscriptions/subscription-details.component";
import {ResetPasswordComponent} from "./pages/reset-password/reset-password.component";
import {subscriptionReducer} from "./store/reducers/subscription.reducer";
import {SubscriptionEffects} from "./store/effects/subscription.effects";

const routes: Routes = [

  {
    path: 'auth/login',
    component: LoginComponent
  },
  {
    path: 'auth/register',
    component: RegisterComponent,
  },
  {
    path: 'auth/userDetails',
    component: UserDetailsComponent,
  },
  {
    path: 'auth/subscription',
    component: SubscriptionComponent,
  },
  {
    path: 'auth/resetPassword',
    component: ResetPasswordComponent,
  },
  {
    path: '',
    component: LoginComponent
  },
];

@NgModule({
  imports: [
    RouterModule.forChild(routes),
    StoreModule.forFeature('auth', authReducer),
    StoreModule.forFeature('subscription', subscriptionReducer),
    EffectsModule.forFeature([
      AuthEffects,
      SubscriptionEffects
    ])
  ],
  exports: [RouterModule]
})
export class AuthRoutingModule {
}
