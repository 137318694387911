<div class="password-reset-container">
  <h2 class="password-reset-title">Reset Password</h2>

  <form [formGroup]="resetPasswordForm" (ngSubmit)="onPasswordChange()" class="password-reset-form">

    <div class="password-change-form">

      <div class="field grid p-field">
        <div class="col">
          <p-floatLabel>
            <input pInputText type="text" id="username" aria-describedby="currentPassword-help"
                   formControlName="username"
                   class="inputtext component"/>
            <label for="currentPassword" class="p-d-block">User Name</label>

          </p-floatLabel>
        </div>
      </div>

      <div class="field grid p-field">
        <div class="col">
          <p-floatLabel>
            <input pInputText type="password" id="currentPassword" aria-describedby="currentPassword-help"
                   formControlName="currentPassword"
                   class="inputtext component"/>
            <label for="currentPassword" class="p-d-block">Current Password</label>

          </p-floatLabel>
        </div>
      </div>

      <div class="field grid p-field">
        <div class="col">
          <p-floatLabel>
            <input pInputText type="password" id="newPassword" aria-describedby="newPassword-help"
                   formControlName="newPassword"
                   class="inputtext component"/>
            <label for="newPassword" class="p-d-block">New Password</label>

          </p-floatLabel>
        </div>
      </div>

      <div class="field grid p-field">
        <div class="col">
          <p-floatLabel>
            <input pInputText type="password" id="confirmNewPassword" aria-describedby="confirmNewPassword-help"
                   formControlName="confirmNewPassword"
                   class="inputtext component"/>
            <label for="confirmNewPassword" class="p-d-block">Confirm New Password</label>

          </p-floatLabel>
        </div>
      </div>

      <div class="p-field p-mt-3 btn-container">
        <button
          pButton
          class="p-button-primary password-reset-button"
          type="submit"
        >
          Change Password
        </button>
      </div>
    </div>
  </form>
</div>
