import {Component} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, ReactiveFormsModule, Validators} from '@angular/forms';
import {Store} from "@ngrx/store";
import {AuthState} from "../../store/reducers/auth.reducer";
import {InputTextModule} from "primeng/inputtext";
import {FloatLabelModule} from "primeng/floatlabel";
import {ButtonDirective} from "primeng/button";
import * as AuthActions from "../../store/actions/auth.action";

@Component({
  standalone: true,
  imports: [
    ReactiveFormsModule,
    InputTextModule,
    FloatLabelModule,
    ButtonDirective
  ],
  templateUrl: './reset-password.component.html',
  styleUrl: './reset-password.component.css'
})
export class ResetPasswordComponent {
  resetPasswordForm: FormGroup;

  constructor(private fb: FormBuilder, private store: Store<AuthState>) {
    this.resetPasswordForm = this.fb.group({
      username: new FormControl('', Validators.required),
      currentPassword: new FormControl('', Validators.required),
      newPassword: new FormControl('', Validators.required),
      confirmNewPassword: new FormControl('', Validators.required)
    })
  }

  onPasswordChange() {
    if (this.resetPasswordForm.status == "VALID" && this.resetPasswordForm.value.confirmNewPassword == this.resetPasswordForm.value.newPassword) {
      this.store.dispatch(AuthActions.changePasswordStart({
        username: this.resetPasswordForm.value.username,
        password: this.resetPasswordForm.value.currentPassword,
        newPassword: this.resetPasswordForm.value.newPassword,
      }));

    }
  }
}
