import {createReducer, on} from '@ngrx/store';
import * as AuthActions from '../actions/auth.action';
import {emptyUser, UserDto} from "../models/User.model";

export interface AuthState {
  user: UserDto;
  error: string | null;
  loading: boolean;
}

const initialState: AuthState = {
  user: emptyUser,
  error: null,
  loading: false
};

export const authReducer = createReducer(
  initialState,
  on(AuthActions.loginSuccess, (state, {user}) => ({
    ...state,
    user,
    loading: false,
    error: null
  })),
  on(AuthActions.loginFailure, (state, {error}) => ({
    ...state,
    loading: false,
    error
  })),
  on(AuthActions.registerStart, (state) => ({
    ...state,
    loading: true,
    error: null
  })),
  on(AuthActions.registerSuccess, (state, {ssid, userKey}) => ({
    ...state,
    userKey,
    ssid,
    loading: false,
    error: null
  })),
  on(AuthActions.registerFailure, (state, {error}) => ({
    ...state,
    loading: false,
    error
  })),
  on(AuthActions.autoAuthenticationSuccess, (state, {user}) => ({
    ...state,
    user,
    loading: false,
    error: null
  })),
  on(AuthActions.logout, (state) => {

    localStorage.removeItem('token');
    localStorage.removeItem('userKey');
    localStorage.removeItem('ssid');

    return {
      ...state,
      user: emptyUser
    }
  }),
  on(AuthActions.loadAuthFromLocalStorage, (state) => {
    const token = localStorage.getItem('token');
    const userKey = localStorage.getItem('userKey');
    const ssid = localStorage.getItem('ssid');
    return {
      ...state,
      token: token ? token : null,
      userKey: userKey ? userKey : null,
      ssid: ssid ? ssid : null,
    };
  }),
  on(AuthActions.updateMyInfoSuccess, (state, {user}) => ({
    ...state,
    user,
    loading: false,
    error: null
  })),
  on(AuthActions.updateMyInfoFailure, (state, {error}) => ({
    ...state,
    loading: false,
    error
  })),
  on(AuthActions.changePasswordSuccess, (state, {user}) => ({
    ...state,
    loading: false,
    error: null
  })),
  on(AuthActions.changePasswordFailure, (state, {error}) => ({
    ...state,
    loading: false,
    error
  })),
);
